import { Suspense, lazy, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import '@bluecodecom/fonts';

import { LoaderProvider } from '@bluecodecom/loader';

import { ThemeProvider } from '@bluecodecom/theme';

import { nativeService } from '@bluecodecom/native';

import { sessionService } from '@bluecodecom/pay-at-pump-webview/features/session';
import { trackingService } from '@bluecodecom/pay-at-pump-webview/features/tracking';

import './i18n';

const App = lazy(() => import('./app'));

const Main = () => {
  useEffect(() => {
    nativeService.hideNavigation();
    nativeService.allowResumeMiniApp();

    trackingService.provider = sessionService.provider;
    trackingService.trackEvent('Opened');
  }, []);

  return (
    <LoaderProvider>
      <ThemeProvider observe>
        <Suspense>
          <App />
        </Suspense>
      </ThemeProvider>
    </LoaderProvider>
  );
};

createRoot(document.getElementById('root') as HTMLElement).render(<Main />);
